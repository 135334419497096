import actionTypes from '../actions/_actionTypes.js'
import {
	fetchReducer, fetchReducerRejected, fetchReducerFulfilled,
	fetchReducerErrorHide
} from './webserviceFetchReducer'
import webserviceFetchArrayReducer from './webserviceFetchArrayReducer'

export const initialState = {
	serverPoolId: null,
	loggedIn: false,
	loggingOut: false,
	currentTimestamp: null,
	sessionId: "",
	externalServerUrl: null,
	userData: [],
	userInfo: [],
	userLoggedIn: [],
	userRemindPassword: [],
	accountList: [],
	accountUpdate: [],
	accountChangePassword: [],
	cardList: [],
	cardSave: [],
	cardDelete: [],
	cardGroupList: [],
	cardGroupDelete: [],
	cardGroupSave: [],
	scannedCardList: [],
	scannedCardDelete: [],
	scannedCardGroupAssignment: [],
	cardProjectList: [],
	cardProjectSave: [],
	cardProjectDelet: [],
	cardProjectAssignment: [],
	linkList: [],
	linkSave: [],
	linkDelete: [],
}

export default function reducer(state = initialState, action) {

	const split = action.type.split("/");
	const action_type = split[0];
	// const subaction_type = split[1];

	switch (action_type) {
		case actionTypes.WS_USER_LOG_IN: {
			const userData = { ...fetchReducer(state.userData, action) }
			return { ...state, userData, loggedIn: false }
		}
		case actionTypes.WS_USER_LOG_IN_REJECTED: {
			const userData = { ...fetchReducerRejected(state.userData, action) }
			return { ...state, userData, loggedIn: false }
		}
		case actionTypes.WS_USER_LOG_IN_FULFILLED: {
			const userData = { ...fetchReducerFulfilled(state.userData, action) }
			const serverPoolId = action.payload.serverPoolId
			return { ...state, userData, serverPoolId, loggedIn: true }
		}
		case actionTypes.WS_USER_LOG_IN_ERROR_HIDE: {
			const userData = { ...fetchReducerErrorHide(state.userData, action) }
			return { ...state, userData, loggedIn: true }
		}
		case actionTypes.WS_USER_LOGGED_IN: {
			const { userLoggedIn } = state
			const newUserLoggedIn = webserviceFetchArrayReducer(userLoggedIn, action)
			return { ...state, userLoggedIn: newUserLoggedIn, loggedIn: newUserLoggedIn[0].data }
		}
		case actionTypes.WS_USER_LOG_OUT: {
			const userData = { ...fetchReducer(state.userData, action) }
			return { ...state, userData, loggingOut: true }
		}
		case actionTypes.WS_USER_LOG_OUT_REJECTED: {
			const userData = { ...fetchReducerRejected(state.userData, action) }
			return { ...state, userData, loggedIn: true, loggingOut: false }
		}
		case actionTypes.WS_USER_LOG_OUT_FULFILLED: {
			return { ...initialState, sessionId: state.sessionId, loggingOut: true, loggedIn: false }
		}
		case actionTypes.WS_USER_REMIND_PASSWORD: {
			const { userRemindPassword } = state
			const newUserRemindPassword = webserviceFetchArrayReducer(userRemindPassword, action)
			return { ...state, userRemindPassword: newUserRemindPassword }
		}

		case actionTypes.WS_SET_WSPATH_AND_SP: {
			const { wsPath, serverPoolId } = action.payload
			return { ...state, wsPath, serverPoolId }
		}

		case actionTypes.WS_SET_SESSION_ID: {
			const { sessionId } = action.payload
			return { ...state, sessionId }
		}
		case actionTypes.WS_SET_EXTERNAL_SERVER: {
			const { externalServerUrl } = action.payload
			return { ...state, externalServerUrl }
		}
		case actionTypes.WS_USER_INFO: {
			const { userInfo } = state
			const newUserInfo = webserviceFetchArrayReducer(userInfo, action)
			return { ...state, userInfo: newUserInfo }
		}

		case actionTypes.WS_ACCOUNT_LIST: {
			const { accountList } = state
			const newAccountList = webserviceFetchArrayReducer(accountList, action)
			return { ...state, accountList: newAccountList }
		}
		case actionTypes.WS_ACCOUNT_UPDATE: {
			const userData = { ...fetchReducerFulfilled(state.userData, action) }
			return { ...state, userData }
		}
		case actionTypes.WS_ACCOUNT_CHANGE_PASSWORD: {
			const { accountChangePassword } = state
			const newAccountChangePassword = webserviceFetchArrayReducer(accountChangePassword, action)
			return { ...state, accountChangePassword: newAccountChangePassword }
		}
		case actionTypes.WS_CARD_LIST: {
			const { cardList } = state
			const newCardList = webserviceFetchArrayReducer(cardList, action)
			return { ...state, cardList: newCardList }
		}
		case actionTypes.WS_CARD_SAVE: {
			const { cardSave } = state
			const newCardSave = webserviceFetchArrayReducer(cardSave, action)
			return { ...state, cardSave: newCardSave }
		}
		case actionTypes.WS_CARD_DELETE: {
			const { cardDelete } = state
			const newCardDelete = webserviceFetchArrayReducer(cardDelete, action)
			return { ...state, cardDelete: newCardDelete }
		}
		case actionTypes.WS_CARD_GROUP_LIST: {
			const { cardGroupList } = state
			const newCardGroupList = webserviceFetchArrayReducer(cardGroupList, action)
			return { ...state, cardGroupList: newCardGroupList }
		}
		case actionTypes.WS_CARD_GROUP_SAVE: {
			const { cardGroupSave } = state
			const newCardGroupSave = webserviceFetchArrayReducer(cardGroupSave, action)
			return { ...state, cardGroupSave: newCardGroupSave }
		}
		case actionTypes.WS_CARD_GROUP_DELETE: {
			const { cardGroupDelete } = state
			const newCardGroupDelete = webserviceFetchArrayReducer(cardGroupDelete, action)
			return { ...state, cardGroupDelete: newCardGroupDelete }
		}
		case actionTypes.WS_SCANNED_CARD_LIST: {
			const { scannedCardList } = state
			const newScannedCardList = webserviceFetchArrayReducer(scannedCardList, action)
			return { ...state, scannedCardList: newScannedCardList }
		}
		case actionTypes.WS_SCANNED_CARD_DELETE: {
			const { scannedCardDelete } = state
			const newScannedCardDelete = webserviceFetchArrayReducer(scannedCardDelete, action)
			return { ...state, scannedCardDelete: newScannedCardDelete }
		}
		case actionTypes.WS_SCANNED_CARD_GROUP_ASSIGNMENT: {
			const { scannedCardGroupAssignment } = state
			const newScannedCardGroupAssignment = webserviceFetchArrayReducer(scannedCardGroupAssignment, action)
			return { ...state, scannedCardGroupAssignment: newScannedCardGroupAssignment }
		}
		case actionTypes.WS_CARD_PROJECT_LIST: {
			const { cardProjectList } = state
			const newCardProjectList = webserviceFetchArrayReducer(cardProjectList, action)
			return { ...state, cardProjectList: newCardProjectList }
		}
		case actionTypes.WS_CARD_PROJECT_SAVE: {
			const { cardProjectSave } = state
			const newCardProjectSave = webserviceFetchArrayReducer(cardProjectSave, action)
			return { ...state, cardProjectSave: newCardProjectSave }
		}
		case actionTypes.WS_CARD_PROJECT_DELETE: {
			const { cardProjectDelete } = state
			const newCardProjectDelete = webserviceFetchArrayReducer(cardProjectDelete, action)
			return { ...state, cardProjectDelete: newCardProjectDelete }
		}
		case actionTypes.WS_CARD_PROJECT_ASSIGNMENT: {
			const { cardProjectAssignment } = state
			const newCardProjectAssignment = webserviceFetchArrayReducer(cardProjectAssignment, action)
			return { ...state, cardProjectAssignment: newCardProjectAssignment }
		}
		case actionTypes.WS_RIGHTS_SAVE: {
			const { rightsSave } = state
			const newRightsSave = webserviceFetchArrayReducer(rightsSave, action)
			return { ...state, rightsSave: newRightsSave }
		}
		case actionTypes.WS_OBJECT_LIST: {
			const { objectList } = state
			const newObjectList = webserviceFetchArrayReducer(objectList, action)
			return { ...state, objectList: newObjectList }
		}
		case actionTypes.WS_LINK_LIST: {
			const { linkList } = state
			const newLinkList = webserviceFetchArrayReducer(linkList, action)
			return { ...state, linkList: newLinkList }
		}
		case actionTypes.WS_LINK_SAVE: {
			const { linkSave } = state
			const newLinkSave = webserviceFetchArrayReducer(linkSave, action)
			return { ...state, linkSave: newLinkSave }
		}
		case actionTypes.WS_LINK_DELETE: {
			const { linkDelete } = state
			const newLinkDelete = webserviceFetchArrayReducer(linkDelete, action)
			return { ...state, linkDelete: newLinkDelete }
		}
		
		default:
			return state;
	}
}