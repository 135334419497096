import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Alert from '@mui/material/Alert';
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import linearGradient from "assets/theme/functions/linearGradient";
import CircularProgress from '@mui/material/CircularProgress';
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import defaultProfile from 'assets/images/default-profile.svg'

// Images
import googlePlay from "assets/images/google_play.png";
import appStore from "assets/images/app_store.png";
import { apiUrl } from "helpers/const";

function getProperUrl(url){
  if(url.substr(0, 8) !== "https://" && url.substr(0, 7) !== "http://"){
    return "https://"+url;
  } else {
    return url;
  }
}

function CardDataWithLinks() {
  const [cardData, setCardData] = useState("");

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const cardId = queryParameters.get("id")
    let fetchAddress = apiUrl
    fetch(fetchAddress + "card/" + cardId, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.text();
          toast.error(JSON.parse(text).error)
          return;
        } else {
          const fetchCardData = await response.json();
          if (fetchCardData != null) {
            setCardData(fetchCardData)
          }
        }
      })
      .catch(async response => {
        const text = response.text;
        if (response.status != null) {
          switch (response.status) {
            case 500: response.errorMessage = response.statusText + " (" + response.status + ")"; break;
            default: response.errorMessage = JSON.parse(text).error; break;
          }
        }
        if (response.message != null) response.errorMessage = "Brak połączenia z serwerem"
        toast.error("Błąd! " + response.errorMessage)
        return;
      })
  }, []);

  let bgColor = "grey"
  if (cardData != null) {
    if (cardData.card_project == null) {
      bgColor = linearGradient("#EE5B5B", "#CD2F2F", 135)
    } else {
      bgColor = linearGradient(cardData.card_project.bg_color_from, cardData.card_project.bg_color_to, 135)
    }
  }
  return (
    <MDBox display="flex" flexDirection="column">
      <MDTypography display="flex" alignItems="center" flexDirection="column" variant="h5" color="dark-grey" fontWeight="medium" sx={{ mt: 3, mb: 5, pb: 1 }}>
        E-WIZYTÓWKA
      </MDTypography>
      
        {/* <MDBox component="img" src={brand} alt="Brand" width="2rem" /> */}
        {(cardData === "") ? <MDBox display="flex" alignItems="center" flexDirection="column"><CircularProgress size="60px" /></MDBox> : 
        (!Object.keys(cardData).length) ? <MDBox display="flex" alignItems="center" flexDirection="column">
          <Alert severity="error">Brak karty o podanym identyfikatorze</Alert>
        </MDBox> :
        <>
        <MDBox 
          /* padding="15pt"  */
          fontFamily='"Montserrat", sans-serif!important' fontOpticalSizing='auto' fontStyle="normal" 
          display="flex" alignSelf="center" flexDirection="column"  justifyContent="space-between"
          /* width={"430pt"} */
          /* height={"250pt"} */ 
          sx={{
            width: {
              xs: 350,
              sm: 350,
              md: 450,
              lg: 450,
              xl: 450,
              xxl: 450
            },
            height: {
              xs: 195,
              sm: 195,
              md: 250,
              lg: 250,
              xl: 250,
              xxl: 250
            },
            padding: {
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 3,
              xxl: 3
            },
          }}
          bgColor={bgColor} 
          border="0px solid rgba(0, 0, 0, 0.125)" borderRadius="0.75rem" boxShadow="rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem"
        >
          <MDBox 
            component="img" borderRadius="50px" src={(cardData.photo_url != null && cardData.photo_url !== "") ? cardData.photo_url : defaultProfile} alt="Zdjęcie profilowe" 
            /* width="90pt" 
            height="90pt"  */
            sx={{
              width: {
                xs: 70,
                sm: 70,
                md: 90,
                lg: 90,
                xl: 90,
                xxl: 90
              },
              height: {
                xs: 70,
                sm: 70,
                md: 90,
                lg: 90,
                xl: 90,
                xxl: 90
              }
            }}
          />
          <MDBox display="flex" flexDirection="column">
          <MDTypography variant="div" fontWeight="600" color="white" fontFamily='"Montserrat", sans-serif!important'
            /* fontSize="16"  */
            sx={{
              fontSize: {
                xs: 18,
                sm: 18,
                md: 23,
                lg: 23,
                xl: 23,
                xxl: 23
              },
              /* marginTop: {
                xs: 11.5,
                sm: 11.5,
                md: 14.63,
                lg: 14.63,
                xl: 14.63,
                xxl: 14.63
              } */
            }}
            /* sx={{ mt: 3, mb: 5, pb: 1 }} */
          >
            {cardData != null && cardData.name}
          </MDTypography>
          <MDTypography variant="div" color="white" fontWeight="500" fontFamily='"Montserrat", sans-serif!important'
            /* fontSize="12"  */
            sx={{
              fontSize: {
                xs: 14,
                sm: 14,
                md: 18,
                lg: 18,
                xl: 18,
                xxl: 18
              },
              /* marginTop: {
                xs: 12.5,
                sm: 12.5,
                md: 16.25,
                lg: 16.25,
                xl: 16.25,
                xxl: 16.25
              } */
            }}
            /* sx={{ mt: 3, mb: 5, pb: 1 }} */
          >
            {cardData != null && cardData.job_position} w {cardData != null && cardData.company_name}
          </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox display="flex" flexDirection="column" marginTop="40px" alignItems="center">
          <MDTypography variant="div" fontWeight="600" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                }
              }}
            >
              {cardData != null && cardData.name+" "+cardData.surname}
            </MDTypography>
            <MDTypography variant="div" fontWeight="600" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                }
              }}
            >
               {cardData != null && cardData.job_position} w {cardData != null && cardData.company_name}
            </MDTypography>
            <MDTypography variant="div" fontWeight="600" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                }
              }}
            >
               {cardData != null && <a href={"tel:"+cardData.phone}>{cardData.phone}</a>}
            </MDTypography>
            <MDTypography variant="div" fontWeight="600" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                }
              }}
            >
               {cardData != null && <a href={"mailto:"+cardData.email}>{cardData.email}</a>}
            </MDTypography>
            <MDTypography variant="div" fontWeight="600" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                }
              }}
            >
               {cardData != null && <a target="_blank" rel="noopener noreferrer" href={cardData.site_url}>{cardData.site_url}</a>}
            </MDTypography>
        </MDBox>

        {(cardData != null && cardData.about_me != null) && <MDBox display="flex" flexDirection="column" marginTop="40px" alignItems="center">
          <MDTypography variant="div" fontWeight="800" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                },
                fontWeight: 600
              }}
            >
              O mnie
            </MDTypography>
            <MDTypography variant="div" fontWeight="600" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                }
              }}
            >
              {cardData.about_me}
            </MDTypography>
        </MDBox>}

        {!!(cardData != null && cardData.user_links != null && cardData.user_links.length) && <MDBox display="flex" flexDirection="column" marginTop="40px" alignItems="center">
          <MDTypography variant="div" fontWeight="800" color="black" fontFamily='"Montserrat", sans-serif!important'
              sx={{
                fontSize: {
                  xs: 12,
                  sm: 12,
                  md: 14,
                  lg: 14,
                  xl: 14,
                  xxl: 14
                },
                fontWeight: 600
              }}
            >
              Linki
            </MDTypography>
            {cardData.user_links.map((link, idx) => <MDBox key={"linkBtn"+idx} pt={2} px={2} display="flex" alignItems="center"><MDButton variant="outlined" color="info" size="small" fullWidth onClick={() => window.open(getProperUrl(link.url), "_blank")}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div>{link.title}</div>
                  <div style={{fontSize: 8}}>{link.description}</div>
                </div>
              </MDButton></MDBox>)}
        </MDBox>}
        </>}


      
      <MDBox /* position="absolute" bottom="50px"  */width="100%" display="flex" flexDirection="column" alignItems="center" marginTop="100px" marginBottom="50px">
        <MDBox display="flex" flexDirection="row" alignSelf="center">
          <MDBox 
            component="img" 
            cursor="pointer"
            src={googlePlay} 
            style={{cursor: "pointer"}}
            onClick={() => window.open("https://play.google.com/store/apps/details?id=com.lulus1511.businesscards&hl=pl", "_blank")}
            alt="Pobierz z Google Play" 
            sx={{
              width: {
                xs: 100,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 100,
                xxl: 100
              }
            }}
          />
          <MDBox width="5pt"></MDBox>
          <MDBox 
            component="img"
            cursor="pointer"
            src={appStore} 
            onClick={() => window.open("https://apps.apple.com/pl/app/e-wizyt%C3%B3wki/id6670243624?l=pl", "_blank")}
            style={{cursor: "pointer"}}
            alt="Pobierz z App Store" 
            sx={{
              width: {
                xs: 100,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 100,
                xxl: 100
              }
            }} 
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default CardDataWithLinks;
