// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { MuiColorInput } from 'mui-color-input'
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import React, { useState, useEffect } from "react";
import { wsLinkList } from "actions/webserviceActions";
import { connect } from 'react-redux'
import { PropTypes } from "prop-types";
import { fetchIsFulfilled } from "utils/fetchStateSelector";
import MDButton from "components/MDButton";
import { Button, Icon, TextField, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { FormControl } from '@mui/base/FormControl';
import { wsLinkSave } from "actions/webserviceActions";
import { wsLinkDelete } from "actions/webserviceActions";
import { ModalContent, Modal, StyledBackdrop } from "components/Modal";
import { wsLinkDeleteMemRelease } from "actions/webserviceActions";
import { wsLinkSaveMemRelease } from "actions/webserviceActions";
import MDInput from "components/MDInput";

function Links({ dispatch, linkList, linkSave, linkDelete }) {
  const [urlError, setUrlError] = useState("");
  const [links, setLinks] = useState([])
  const [open, setOpen] = useState(false);
  const [linkData, setLinkData] = useState({ url: "", title: "", description: "" })
  const handleOpen = (edit) => { 
    setUrlError(""); 
    if(!edit){
      setLinkData({ url: "", title: "", description: "" }); 
    }
    setOpen(true) 
  };
  const handleClose = () => { setUrlError(""); setOpen(false) };
  const columns = [{ Header: "URL", accessor: "url", width: "25%", align: "left" }, { Header: "Tytuł", accessor: "title", width: "25%", align: "left" }, { Header: "Opis", accessor: "description", width: "30%", align: "left" },
  { Header: "Akcja", accessor: "actions", align: "left" }]

  const onEdit = (item) => {
    setLinkData({ ...item })
    handleOpen(true)
  }

  const onDelete = (item) => {
    dispatch(wsLinkDelete({ id: "global" }, item.id))
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setLinkData(values => ({ ...values, [name]: value }))
  }

  useEffect(() => {
    dispatch(wsLinkList({ id: "global" }))
  }, []);

  useEffect(() => {
    if (fetchIsFulfilled(linkList)) {
      let rows = []
      if (linkList.data != null && linkList.data.length) {
        linkList.data.forEach(link => {
          rows.push({ ...link, actions: (<><Button onClick={() => onEdit(link)}>Edycja</Button><Button onClick={() => onDelete(link)}>Usuń</Button></>) })
        })
      }
      setLinks(rows)
    }
  }, [linkList.data]);

  useEffect(() => {
    if (fetchIsFulfilled(linkSave)) {
      dispatch(wsLinkList({ id: "global" }))
      dispatch(wsLinkSaveMemRelease({ id: "global" }))
      handleClose()
    }
  }, [linkSave.data]);

  useEffect(() => {
    if (fetchIsFulfilled(linkDelete)) {
      dispatch(wsLinkList({ id: "global" }))
      dispatch(wsLinkDeleteMemRelease({ id: "global" }))
    }
  }, [linkDelete.data]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (linkData.url == null || linkData.url === "") {
      setUrlError("Odnośnik URL nie może być pusty")
    } else {
      dispatch(wsLinkSave({ id: "global" }, { ...linkData }))
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Moje linki
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={() => { handleOpen(false) }}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;dodaj
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: links }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <Modal
                  aria-labelledby="unstyled-modal-title"
                  aria-describedby="unstyled-modal-description"
                  open={open}
                  onClose={handleClose}
                  slots={{ backdrop: StyledBackdrop }}
                >
                  <ModalContent sx={{ width: 400 }}>
                    <h2 id="unstyled-modal-title" className="modal-title" style={{ marginBottom: 20 }}>
                      {linkData.id != null ? "Edytuj" : "Dodaj"} link
                    </h2>
                    <FormControl defaultValue="" required >
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        required
                        label="Odnośnik URL"
                        error={urlError}
                        helperText={urlError}
                        name="url"
                        value={linkData.url}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        required
                        label="Tytuł"
                        name="title"
                        value={linkData.title}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        required
                        label="Opis"
                        name="description"
                        value={linkData.description}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <MDButton variant="gradient" color="dark" onClick={onSubmit} >
                        <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                        &nbsp;zapisz
                      </MDButton>
                    </FormControl>
                  </ModalContent>
                </Modal>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Links.propTypes = {
  dispatch: PropTypes.func,
  linkList: PropTypes.object,
  linkSave: PropTypes.object,
  linkDelete: PropTypes.object
}
export default connect(store => {
  let linkList = (store.webservice.linkList != null && store.webservice.linkList.length) ? store.webservice.linkList[0] : []
  let linkSave = (store.webservice.linkSave != null && store.webservice.linkSave.length) ? store.webservice.linkSave[0] : []
  let linkDelete = (store.webservice.linkDelete != null && store.webservice.linkDelete.length) ? store.webservice.linkDelete[0] : []
  return { linkList, linkSave, linkDelete }
})(Links);