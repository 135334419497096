/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import addImage from 'assets/images/add-image.svg'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { toast } from "react-toastify";
// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import MDButton from "components/MDButton";
import { resizeBase64Img } from "utils/helpers";
import { wsAccountUpdate } from "actions/webserviceActions";

function Header({ children, setTabValue, tabValue, fullName, profileId, photoUrl, userData, dispatch }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [imageFile, setImageFile] = useState("")
  
  //const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if(photoUrl != null && photoUrl !== ""){
      setImageFile(photoUrl)
    }
  }, []);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleCaptureFile = ({ target }) => {
    const fileReader = new FileReader();
    const name = target.accept.includes('image') ? 'images' : 'videos';

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
       /*  this.setState((prevState) => ({
            [name]: [...prevState[name], e.target.result]
        })); */
        
        resizeBase64Img(e.target.result, 100, 100).then((result)=>{
          setImageFile(result)
          dispatch(wsAccountUpdate({id: "global"}, {account_id: userData.account_id, photo: result}))
          toast.success("Zdjęcie profilowe zostało zmienione");
        });
        
    };
};

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {/* <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" /> */}
            <input
                        accept="image/*"
                        //className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        onChange={handleCaptureFile}
                        type="file"
                      />
                      
                      <label htmlFor="raised-button-file" cursor="pointer">
                        <MDBox component="img" border="1px solid transparent" bgColor={imageFile !== "" ? "transparent" : "#EE5B5B"} borderRadius="50px" src={imageFile !== "" ? imageFile : addImage} alt="Zdjęcie profilowe" width="70px" height="70px" />
                        {/* <Button variant="raised" component="span">
                          Wybierz
                        </Button> */}
                      </label> 
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {fullName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item>
          <MDButton variant="gradient" size="small" color="info" fullWidth onClick={() => window.open("https://ewizytowki.vehicleapp.pl/?profile="+profileId, "_blank")}>
                      podgląd
                    </MDButton>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Dane"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      home
                    </Icon>
                  }
                />
                <Tab
                  label="Zmień hasło"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      settings
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  tabValue: PropTypes.number,
  setTabValue: PropTypes.func,
  fullName: PropTypes.string,
  profileId: PropTypes.string,
  photoUrl: PropTypes.string,
  userData: PropTypes.object,
  dispatch: PropTypes.func,
};

export default Header;
